.bea-button {
    display: inline-flex;
    padding: 12px;
    align-items: center;
    gap: 12px;
    border-radius: 2px;
    height: 40px;
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
}

/* PRIMARY VARIANT */
.bea-primary {
    background-color: #FF832B;
    color: var(--main-text-color);
}

.bea-primary:hover {
    background-color: #FA6800;
    cursor: pointer;
}

.bea-primary:active {
    background-color: #BA4E00;
}

.bea-primary:focus {
    background-color: #FF832B;
    color: var(--main-text-color);
}

/* SECONDARY VARIANT */
.bea-secondary {
    background-color: #393939;
    color: #FFFFFF;
}

.bea-secondary:hover {
    background-color: #4C4C4C;
    color: #FFFFFF;
    cursor: pointer;
}

.bea-secondary:active {
    background-color: #6F6F6F;
}

.bea-secondary:focus {
    background-color: #393939;
    border: 1px solid #FF832B;
}

/* GHOST VARIANT */
.bea-ghost {
    color: #FF832B;
}

.bea-ghost:hover {
    cursor: pointer;
    background: #E5E5E5;
    color: #EB6200;
}

.bea-ghost:active {
    cursor: pointer;
    background: #C6C6C6;
    color: #BA4E00;
}

.bea-ghost:focus {
    border: 1px solid #FF832B;
    color: #FF832B;
}

/* disabled state */
.bea-primary-disabled {
    background-color: #C6C6C6;
    color: #8D8D8D;
    cursor: not-allowed !important;
}

.bea-secondary-disabled {
    cursor: not-allowed !important;
    background-color: #C6C6C6;
    color: #8D8D8D;
}

.bea-ghost-disabled {
    cursor: not-allowed !important;
    background-color: #FFFFFF;
    color: #8D8D8D;
}