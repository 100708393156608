 .settings-create-form {
     width: 100%;
     margin-top: 24px;
     min-height: 100px;
 }

 .settings-create-form .label {
     font-family: 'IBM Plex Sans';
     font-style: normal;
     font-weight: 400;
     font-size: 12px;
     line-height: 16px;
     display: flex;
     align-items: center;
     letter-spacing: 0.32px;
     color: #525252;
 }

 .settings-notification {
     position: absolute;
     right: 16px;
     top: 72px;
     z-index: 9999;
 }