.dropdown-timezone-selector {
    border-radius: 2px;
    background-color: #fff;
    border-color: #E0E0E0;
}

.cds--select-input {
    background-color: #ffffff !important;
    border: 1px solid #E0E0E0;
    border-radius: 2px;
    outline: none;
}

.cds--select-input .cds--select-option {
    background-color: #ffffff !important;
    text-transform: uppercase !important;
}

.new-tz-selector {
    width: 50px;
    height: 40px;
    margin-left: 2px;
    margin-right: 2px;
    cursor: pointer;
    text-transform: uppercase;
}
