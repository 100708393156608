:root {
    --cds-field: #fff;
    --cds-layer-01: var(--cds-field);
    --cds-code-02-font-family: 'IBM Plex Sans';
    --cds-code-02-font-weight: 400;
    --cds-button-primary: #FF832B;
    --cds-text-on-color: #fff;
    --cds-link-primary: #FF832B;
    --color-e0: #E0E0E0;
    --color-e5: #E5E5E5;
    --color-f4: #F4F4F4;
    --main-text-color: #161616;
    --sub-text-color: #525252;
}

.App {
    text-align: center;
}

body {
    overflow: hidden;
}

th {
    background-color: #f4f4f4 !important;
    border-bottom: 1px solid var(--color-e0);
}

h2 {
    padding: 32px 0px 25px 0px;
    margin: 0px 16px 0 16px;
    border-bottom: 1px solid var(--color-e0);
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #161616;
}

h3 {
    padding: 16px 0 8px 16px;
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.16px;
    color: #525252;
}

.cds--table-sort {
    background-color: #f4f4f4;
    border-bottom: 1px solid var(--color-e0);
    outline: none;
}

.cds--table-sort:hover {
    background-color: #f4f4f4;
}

.main-container {
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 56px 1fr;
}

.main-content {
    display: grid;
    overflow: hidden;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
}

.cds--text-input {
    background-color: #ffffff !important;
    border: 1px solid var(--color-e0);
    border-radius: 2px;
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #161616;
}

.cds--text-input:focus,
.cds--text-input:active {
    outline: none;
}

.cds--text-input--invalid,
.cds--text-input--invalid:focus,
.cds--text-input--invalid:active {
    padding-right: 1rem;
    border: 1px solid #da1e28;
    border-radius: 2px;
}

.cds--dropdown {
    background-color: #ffffff;
    border: 1px solid var(--color-e0);
    border-radius: 2px;
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #161616;
    outline: none;
}

.cds--list-box__menu:not(:focus) {
    border: none;
}

.cds--list-box__menu:active,
.cds--list-box__menu:focus,
.cds--list-box__field:focus,
.cds--list-box__field:active {
    outline: none !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.cds--list-box__menu {
    background-color: #ffffff;
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #161616;
    outline: none;
    margin-top: 8px;
    border: 1px solid var(--color-e0);
    border-radius: 2px;
    box-shadow: none !important;
    max-height: 13rem !important;
}

.cds--list-box--expanded {
    max-height: 13rem !important;
}

.cds--dropdown:hover {
    background-color: #ffffff;
}

.cds--list-box__menu-item__option {
    border-top: 0px;
}

.cds--list-box__menu-item:active {
    border: 2px solid #ff832b;
    border-radius: 2px;
}

.cds--list-box__menu-item__selected-icon {
    height: 0;
}

.cds--list-box__label {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #161616;
    white-space: normal;
}

.cds--select-input:focus,
.cds--select-input:active {
    outline: none;
}

.cds--tab-content {
    overflow: hidden !important;
}

.cds--tab-content:focus,
.cds--tab-content:active {
    outline: none;
}

.cds--tabs .cds--tabs__nav-link:focus,
.cds--tabs .cds--tabs__nav-link:active {
    outline: none;
}

.cds--table-sort:focus,
.cds--table-sort:active {
    outline: none;
}

.cds--link:focus,
.cds--link:active {
    outline: none;
}

.cds--toggle__switch {
    outline: none !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.cds--list-box__label {
    min-width: 40px;
    max-width: 132px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.label-small span.cds--list-box__label {
    max-width: 70px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cds--date-picker__input {
    border: 1px solid var(--color-e0);
    border-radius: 2px;
}

.cds--date-picker__input:focus, .cds--date-picker__input.cds--focused {
    outline: none;
}

.flatpickr-day.selected {
    background-color: var(--cds-button-primary, #FF832B);
    color: var(--cds-text-on-color, #ffffff);
}

.flatpickr-day.today.selected {
    outline: 2px solid var(--cds-button-primary, #FF832B);
    outline-offset: -2px;
}

.flatpickr-calendar.open {
    margin-top: 8px;
    border-radius: 2px;
    box-shadow: none;
    border: 1px solid var(--color-e0);
}

.flatpickr-calendar.open:focus {
    outline: none;
}

.cds--date-picker__input:disabled {
    border-bottom: 1px solid var(--color-e0);
    background-color: var(--cds-field);
    color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
    cursor: not-allowed;
}

.cds--text-input:disabled {
    outline: 2px solid transparent;
    outline-offset: -2px;
    border-bottom: 1px solid var(--color-e0);
    background-color: var(--cds-field);
    color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
    cursor: not-allowed;
    -webkit-text-fill-color: var(--cds-text-disabled, rgba(22, 22, 22, 0.25));
}

.cds--date-picker__input:disabled:hover {
    border-bottom: 1px solid var(--color-e0);
}

.main-text {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--main-text-color);
    letter-spacing: .16px;
    line-height: 20px;
}

.sub-text {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-size: 12px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--sub-text-color);
    letter-spacing: .32px;
    line-height: 16px;
}

.bea-table {
    display: flex;
    flex-direction: column;
}

.bea-table-header {
    width: 100%;
    display: grid;
    position: sticky;
    top: 0;
    padding-left: 16px;
    padding-right: 12px;
    height: 34px;
    background-color: var(--color-f4);
}

.cell-main-text {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.16px;
    color: #161616;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cell-sub-text {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #525252;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
