.status-btn-container {
    border-left: 1px solid #E0E0E0;
    width: 56px;
    height: 56px;
    flex: none;
    flex-grow: 0;
    outline: none;
}

.status-btn {
    margin: 8px 5px;
    box-shadow: none !important;
    background-color: #fff;
}

.status-btn:hover {
    cursor: pointer;
    background-color: #fff;
}

.status-btn:focus {
    border: none;
}
