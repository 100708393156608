.earthquake-detail-header {
    display: grid;
    grid-template-rows: auto auto;
    padding-top: 24px;
    padding-bottom: 24px;
    gap: 16px;
}

.earthquake-detail-header div {
    display: flex;
    gap: 8px;
    align-items: center;
}

.earthquake-detail-header-tz {
    background: #8D8D8D;
    border-radius: 2px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.32px;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 4px;
}

.origin-preferred {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px;
    gap: 10px;
    height: 20px;
    background: #EB6200;
    border-radius: 2px;
    flex: none;
    order: 7;
    flex-grow: 0;
}

.origin-preferred span {
    height: 12px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.32px;
    color: #FFFFFF;
    text-transform: uppercase;
}

.earthquake-detail-group {
    height: 20px;
    display: flex;
    gap: 4px
}

.earthquake-detail-group .time {
    color: var(--Tokens-Core-Tokens-text-01, #161616);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Bodies/Body short 01 */
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
    letter-spacing: 0.16px;
}

.earthquake-detail-group div.tz {
    display: flex;
    padding: 4px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 2px;
    background: var(--Tokens-Core-Tokens-ui-04, #8D8D8D);
}

.earthquake-detail-group div.tz span {
    color: var(--Tokens-Core-Tokens-inverse-01, #FFF);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: IBM Plex Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    /* 120% */
    letter-spacing: 0.32px;
    text-transform: uppercase;
}

.earthquake-detail-separator {
    display: flex;
    width: 6px;
    height: 6px;
    align-items: center;
    gap: 12px;
    color: #525252;
}

.earthquake-detail-ingroup {
    display: flex;
    padding: 4px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 2px;
    background: var(--Tokens-Core-Tokens-support-04, #EB6200);
}

.earthquake-detail-ingroup-label {
    color: var(--Tokens-Core-Tokens-inverse-01, #FFF);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: IBM Plex Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    /* 120% */
    letter-spacing: 0.32px;
    text-transform: uppercase;
}

.type {
    display: flex;
    align-items: center;
    gap: 4px;
}

.type span{
    text-transform: capitalize;
}

.type .icon {
    width: 20px;
    height: 20px;
}

.type .not-existing {
    color: var(--Tokens-Core-Tokens-support-01, #DA1E28);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "IBM Plex Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.16px;
}

.type .other {
    color: var(--Tokens-Core-Tokens-support-01, #EB6200);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "IBM Plex Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.16px;
}