@use '@carbon/react';

@font-face {
  font-family: 'IBM Plex Sans';
  src: local('IBMPlexSans-Regular'), url('./fonts/IBMPlexSans-Regular.ttf') format('truetype');
}

body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  font-family: 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.spacer-8{
  width: 8px;
  height: 8px;
}

.spacer-16{
  width: 16px;
  height: 16px;
}

.spacer-32{
  width: 32px;
  height: 32px;
}
.spacer-40{
  width: 40px;
  height: 40px;
}
.spacer-48{
  width: 48px;
  height: 48px;
}
.spacer-56{
  width: 56px;
  height: 56px;
}

.spacer-64{
  width: 64px;
  height: 64px;
}
.spacer-72{
  width: 72px;
  height: 72px;
}
.spacer-80{
  width: 80px;
  height: 80px;
}
.spacer-96{
  width: 96px;
  height: 96px;
}
.spacer-160{
  width: 160px;
  height: 160px;
}

.timeline {
  height: 56px;
  width: 100%;
}

// su FIGMA è chiamata navigation bar
.sidebar {
  position: absolute;
  left: 0;
  width: 56px;
  height: 100%;
}

.title {
  color: #161616;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.subtitle {
  color: #525252;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.mt-8 {
  margin-top: 8px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mv-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-24 {
  margin-top: 24px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mv-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-32 {
  margin-bottom: 32px;
}

.me-2 {
  margin-right: 2px;
}

.me-4 {
  margin-right: 4px;
}

.mb-48 {
  margin-bottom: 48px;
}

.ms-2 {
  margin-left: 2px;
}

.ms-4 {
  margin-left: 4px;
}

.mh-12 {
  margin-left: 12px;
  margin-right: 12px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
}

.label {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #525252;
}

.input {
  width: 100%;
}

.bc-refresh-btn {
  width: 40px;
  height: 40px;
  background-color: #393939;
  padding: 8px;
  border-radius: 2px;
}

.bc-refresh-btn:hover {
  background-color: #4C4C4C;
  cursor: pointer;
  padding: 8px;
}

.bc-refresh-btn:focus {
  background-color: #393939;
  border: 2px solid var(--cds-button-primary);
  border-radius: 2px;
  padding: 6px;
}

.bc-refresh-btn:active {
  background-color: #6F6F6F !important;
  border: 1px solid var(--cds-button-primary);
}

.bea-btn {
  position: static;
  background-color: var(--cds-button-primary);
  box-shadow: none !important;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #161616;
  letter-spacing: 1.25px;
  padding: 12px !important;
  text-align: center !important;
  border-radius: 2px;
  text-align: center;
  justify-content: center;
}

.bea-btn:hover {
  background-color: #FA6800;
}

.bea-btn:focus {
  background-color: var(--cds-button-primary);
  border: 2px solid #BA4E00;
  border-radius: 2px;
}

.bea-btn:active {
  background-color: #BA4E00 !important;
  border: 1px solid var(--cds-button-primary);
}

.bea-btn-transparent {
  position: static;
  background-color: #00000000;
  color: var(--cds-button-primary);
  box-shadow: none !important;
  border-radius: 2px;
  letter-spacing: 1.25px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  border-radius: 2px;
  text-align: center;
  padding: 12px;
  justify-content: center;
}

.bea-btn-transparent:hover {
  background-color: #e5e5e5;
  border-radius: 2px;
  color: #EB6200;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: center;
  padding: 10px;
  justify-content: center;
}

.bea-btn-transparent:focus {
  background-color: #00000000;
  border-radius: 2px;
  border: 2px solid var(--cds-button-primary);
  color: var(--cds-button-primary);
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: center;
  padding: 12px;
  justify-content: center;
}

.bea-btn-transparent.active {
  background-color: #C6C6C6;
  border: none;
  color: #BA4E00;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: center;
  padding: 12px;
  justify-content: center;
}

.p-24 {
  padding-top: 24px;
  padding-bottom: 24px;
  position: relative;
}

.bea-btn-black {
  position: static;
  background-color: #393939;
  border-radius: 2px;
  box-shadow: none !important;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding: 12px;
  align-items: center;
  letter-spacing: 1.25px;
  text-align: center;
  justify-content: center;
}

.bea-btn-black:hover {
  background-color: #4C4C4C;
  border-radius: 2px;
  box-shadow: none !important;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding: 10px;
  align-items: center;
  letter-spacing: 1.25px;
  text-align: center;
  justify-content: center;
}

.bea-btn-black:focus {
  background-color: #393939;
  border: 2px solid var(--cds-button-primary);
  color: #ffffff;
  border-radius: 2px;
  box-shadow: none !important;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;  
  padding: 12px;
  align-items: center;
  letter-spacing: 1.25px;
  text-align: center;
  justify-content: center;
}

.bea-btn-black:active {
  background-color: #6F6F6F;
  border: 1px solid var(--cds-button-primary);
  inset: 0;
  color: #ffffff;
  border-radius: 2px;
  box-shadow: none !important;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;  
  padding: 12px;
  align-items: center;
  letter-spacing: 1.25px;
  text-align: center;
  justify-content: center;
}

.p-24 {
  padding-top: 24px;
  padding-bottom: 24px;
  position: relative;
}

.cell-flex .preferred {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.cell-flex {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  color: #161616;
  display: flex;
}

.cds--popover {
  display: none;
}

.mv-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey; 
  border-radius: 4px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: grey; 
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: grey; 
}

.mr-4 {
  margin-right: 4px;
}

.cds--modal-container--xs {
  min-height: 12% !important;
  max-height: 90% !important;
}