.group-options-container {
    max-width: 278px;
    box-sizing: border-box;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    background-color: #ffffff;
    padding: 4px 0px;
    gap: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 26;
}

.group-options-container h4 {
    padding: 8px 16px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.32px;
    color: #161616;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.group-options-container .divider-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 16px;
    gap: 10px;
    max-width: 276px;
    height: 1px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.group-options-container .divider {
    max-width: 244px;
    height: 1px;
    background: #E0E0E0;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.group-options-container .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 64px 9px 16px;
    height: 32px;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
}

.group-options-container .item:hover {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 64px 9px 16px;
    height: 32px;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
    background: #E5E5E5;
    cursor: pointer;
}

.group-options-container .item p {
    height: 16px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.32px;
    color: #393939;
    flex: none;
    order: 0;
    flex-grow: 0;
}