.earlyest-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 12px;
    border-radius: 2px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    cursor: default;
}

.earlyest-button-primary {
    background: #FF832B;
    color: #161616;
}

.earlyest-button-primary:hover {
    background: #FA6800;
    cursor: pointer;
}

.earlyest-button-secondary {
    color: #FFFFFF;
    background: #6F6F6F;
}

.earlyest-button-secondary:hover {
    background: #606060;
    cursor: pointer;
}