.filter-section-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.filter-section-row {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    height: 64px;
    min-height: 64px;
}

.filter-section-col {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
}