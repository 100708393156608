/* Frame 1139 */
.other-chips {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    height: 24px;
    flex: none;
    order: 8;
    flex-grow: 0;
    gap: 4px;
}

.other-chips:hover {
    cursor: pointer;
    background-color: #e0e0e0;
}

.other-chips .label {
    height: 16px;

    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */
    display: flex;
    align-items: center;
    letter-spacing: 0.32px;

    /* Tokens/Core Tokens/interactive-01 */
    color: #FF832B;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.other-chips .icon {

    width: 24px;
    height: 24px;

    /* Inside auto layout */
    display: flex;
    order: 1;
    flex-grow: 0;
    align-items: center;
    justify-content: center;
}

.normal {
    overflow: hidden;
    transition-duration: 0.2s;
    transition-property: transform;
}

.rotate {
    overflow: hidden;
    transition-duration: 0.2s;
    transition-property: transform;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}