.filterbox {
    grid-column: 1 / span 16;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 0;
    padding-bottom: 8px;
    position: relative;
}

.filterbox .remove-button {
    margin: 8px 16px;
    cursor: pointer;
    padding: 0;
    z-index: 50;
}

.filterbox-container {
    position: absolute;
    top: 0;
    left: 0;
    margin: 8px 24px;
    margin-right: 172px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 0;
    padding-right: 69px;
    gap: 8px;
    min-height: 24px;
    overflow-y: hidden;
    z-index: 20;
    background-color: #F4f4f4;
}

.filterbox-chip-transparent {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 0;
    gap: 8px;
    height: 24px;
}

.filterbox-chip {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 6px;
    gap: 8px;
    height: 24px;
    background: #E0E0E0;
    border-radius: 2px;
}

.expand-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    width: 69px;
    height: 24px;
    flex: none;
    order: 9;
    flex-grow: 0;
    border-radius: 2px;
}

.expand-button-container span {
    width: 45px;
    height: 16px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.32px;
    color: #FF832B;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.expand-button-container:hover {
    background-color: #e0e0e0;
    cursor: pointer;
}

.fb-remove-filter {
    display: flex;
    height: 24px;
    gap: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 24px;
}

.fb-remove-filter:hover {
    cursor: pointer;
}

.fb-remove-filter-label {
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-align: left;
    color: #FF832B;
    margin-top: 4px;
    margin-bottom: 4px;
}

.fb-remove-filter-close {
    color: #FF832B;
    height: 24px;
    width: 24px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-align: center;
    padding: 4px;
}

.fb-divider {
    color: #161616;
    font-size: 12px;
    font-family: IBM Plex Sans;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.32px;
    word-wrap: break-word;
}

.fb-bold-text {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #161616;
    max-width: 278px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

.fb-light-text {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #161616;
}