.error-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.error-title {
    min-width: 332px;
    height: 28px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #161616;
}

.error-text {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 0.16px;
    color: #525252;
    margin-top: 16px;
    margin-left: 48px;
    margin-right: 48px;
}