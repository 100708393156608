.doublerowregion-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items:flex-start
}

.doublerowregion-container-single {
    display: grid;
    overflow: hidden;
    grid-template-rows: 1fr;
}

.h-36 {
    height: 36px;
}
