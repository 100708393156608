.filters-area {
    display: grid;
    grid-template-columns: 20% 80%;
    height: 100%;
    max-height: calc(100vh);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 40;
}

.filters-overlay {
    background-color: #0008;
}

.filters-container {
    max-height: calc(100vh);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: none;
    outline: none;
}

.filters-container-header {
    height: 56px;
    max-height: 56px;
    border-bottom: 1px solid var(--color-e0);
    background: #FFFFFF;
    display: flex;
    padding: 8px 24px;
    justify-content: space-between;
    align-items: center;
}

.filters-container-body {
    height: calc(100% - 56px - 78px);
    display: flex;
    flex-direction: column;
}

.filters-selector {
    height: 72px;
    max-height: 72px;
    min-height: 72px;
    display: flex;
    padding: 16px 24px;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    background-color: #F4F4F4;
}

.filters-parts {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: calc(100% - 72px);
}

.filters-container-footer {
    max-height: 78px;
    height: 78px;
    display: flex;
    border-top: 1px solid var(--color-e0);
    padding: 16px 24px;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    background: #FFFFFF;
    z-index: 30;
}

.filters-full {
    max-height: 100%;
    grid-column: 1 / span 3;
    background: var(--color-f4);
    overflow-y: auto;
    overflow-x: auto;
    padding: 24px;
}

.filters-id-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}

.filters-id-title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}

.filters-id-title {
    color: #525252;
    /* Headlines/Productive heading 01 */
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    /* 128.571% */
    letter-spacing: 0.16px;
}

.filters-id-searchbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.filters-id-inputtext {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    position: relative;
}

.filters-id-inputtext-label {
    color: #525252;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: IBM Plex Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.32px;
}

.filters-id-radio {
    display: flex;
    align-items: flex-start;
    gap: 24px;
}

.filters-id-radiooption {
    display: flex;
    align-items: center;
    gap: 4px;
}

.filters-first-part {
    max-height: 100%;
    grid-column: 1 / span 1;
    background: var(--color-f4);
    overflow-y: auto;
    overflow-x: auto;
    padding: 24px;
}

.filters-second-part {
    grid-column: 2 / span 2;
    background: #FFFFFF;
    overflow-y: auto;
    max-height: 100%;
}

.filters-title {
    font-family: IBM Plex Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: var(--main-text-color);
}

.filters-icon-container {
    width: 40px;
    height: 40px;
    padding: 8px;
    align-items: center;
    gap: 12px;
    border-radius: 2px;
}

.filters-icon {
    border-radius: 2px;
}

.filters-icon:hover {
    background-color: #E5E5E5;
    cursor: pointer;
}

.filters-icon:active {
    background-color: #C6C6C6;
}

.filters-icon:focus {
    border: 1px solid #FF832B;
}

.filters-divider {
    width: 1px;
    height: 40px;
    background-color: var(--color-e0);
}

.filters-buttons {
    display: flex;
    align-items: flex-start;
    gap: 16px;
}

.content-switcher {
    display: flex;
    align-items: flex-start;
}

.content-switcher .option {
    display: flex;
    width: 180px;
    height: 40px;
    padding: 0 16px;
    align-items: center;
    gap: 8px;
    border: 1px solid #393939;
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    /* 128.571% */
    letter-spacing: 0.16px;
}

.content-switcher .option:hover {
    cursor: pointer;
}

.left {
    border-radius: 4px 0 0 4px;
}

.right {
    border-radius: 0 4px 4px 0;
}

.selected-content {
    background-color: #393939;
    color: #FFFFFF;
}

.bc-disabled {
    color: #C6C6C6 !important;
}

.bc-cancel {
    position: absolute;
    right: 8px;
    top: 8px;
}

.bc-cancel-error {
    position: absolute;
    right: 32px;
    top: 8px;
}

.bc-cancel:hover {
    cursor: pointer;
}

.bc-cancel-error:hover {
    cursor: pointer;
}

.full-24px {
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.full-no-bottom-24px {
    display: flex;
    padding: 24px 24px 0 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

/* filters sections */
.filters-section-title {
    color: #525252;
    font-feature-settings: 'clig' off, 'liga' off;
    /* Headlines/Productive heading 01 */
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    /* 128.571% */
    letter-spacing: 0.16px;
}

.filters-localspace-selectors {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    flex-shrink: 0;
    align-self: stretch;
}

.filters-localspace-id-container {
    display: flex;
    height: 64px;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
    gap: 8px;
    flex-grow: 2;
    background-color: blue;
}

.filters-localspace-id-label {
    color: #525252;
    font-feature-settings: 'clig' off, 'liga' off;
    /* Other styles/Label 01 */
    font-family: IBM Plex Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.32px;
}

.filters-single-localspace-selector {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.filters-single-localspace-selector span {
    color: #6F6F6F;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: IBM Plex Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.32px;
}