.breadcrumb-container-eqlist {
    grid-column: 1 / span 16;
    display: flex;
    flex-direction: row;
    align-content: center;
    padding: 24px 0 16px 0;
    /* top right bottom left*/
    margin: 0 24px;
    /* vertical horizontal*/
    border-bottom: 1px solid #E0E0E0;
    position: relative;
}

.breadcrumb-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.breadcrumb-container-internal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
}

.breadcrumb-item-simple * {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #161616;
}

.cds--link {
    color: #FF832B !important;
}

.cds--breadcrumb-item--current .cds--link {
    color: #161616 !important;
}

.breadcrumb-item-simple *:hover {
    text-decoration: none;
    color: #161616 !important;
}

.breadcrumb-button {
    height: 40px;
    max-width: 188px;
    border-radius: 2px;
    padding: 8px;
    gap: 12px;
    background-color: #FF832B;
}

.breadcrumb-button:hover {
    cursor: pointer;
    background-color: #EB6200;
}

.breadcrumb-button-label-container {
    padding: 4px;
    gap: 10px;
}

.breadcrumb-button-label {
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-align: left;
    color: #161616;
    text-transform: uppercase;
}

.notification-hover {
    position: absolute;
    top: 72px;
    right: 16px;
    z-index: 99;
}

.bc-vertical-divider {
    height: 40px;
    width: 1px;
    background-color: var(--color-e0);
    margin-right: 16px;
    margin-left: 16px;
}

.bc-rapid-buttons-container {
    display: flex;
    gap: 8px
}

.breadcrumb-row {
    align-self: stretch;
    height: 40px;
    display: flex;
    justify-content: space-between;
}

.breadcrumb-divider {
    height: 1px;
    align-self: stretch;
    background: var(--Tokens-Core-Tokens-ui-03, #E0E0E0);
}

.frame-1109 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
}

.frame-1095 {
    display: flex;
    align-items: center;
}

.bc__edit_type__modal {
    width: 400px;
    position: absolute;
    top: calc((100vh - 282px) / 2);
    left: calc((100vw - 400px) / 2);
    z-index: 90;
    display: inline-flex;
    padding: 16px;
    flex-direction: column;
    gap: 48px;
    border-radius: 2px;
    border: 1px solid var(--Tokens-Core-Tokens-ui-03, #E0E0E0);
    background: var(--Tokens-Core-Tokens-ui-01, #FFF);
}

.bc__edit_type__modal__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}

.bc__edit_type__modal__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    position: relative;
}

.bc__edit_type__modal__text h1 {
    color: var(--Tokens-Core-Tokens-text-01, var(--Default-Gray-100, #161616));
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "IBM Plex Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
}

.bc__edit_type__modal__text p {
    color: var(--Tokens-Core-Tokens-text-02, #525252);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "IBM Plex Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.16px;
}

.bc__edit_type__modal__dropdown {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    flex: 0 100%;
    align-self: stretch;
}

.bc__edit_type__modal__dropdown p {
    color: var(--Tokens-Core-Tokens-text-02, #525252);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "IBM Plex Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.32px;
}

.bc__edit_type__modal__buttons {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: row;
    gap: 16px;
}