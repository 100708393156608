.dd-container {
    display: flex;
    flex-direction: column;
    position: relative;
}

.bc-button {
    width: 40px;
    height: 40px;
    display: flex;
    padding: 8px;
    gap: 12px;
    background-color: #393939;
    border-radius: 2px;
    align-items: center;
    justify-content: center;
    position: relative;
}

.bc-button:hover {
    background-color: #4C4C4C;
    cursor: pointer;
}

.bc-button-active {
    width: 40px;
    height: 40px;
    display: flex;
    padding: 8px;
    gap: 12px;
    border-radius: 2px;
    align-items: center;
    justify-content: center;
    background-color: #4C4C4C;
    position: relative;
}

.bc-button-disabled {
    width: 40px;
    height: 40px;
    display: flex;
    padding: 8px;
    gap: 12px;
    border-radius: 2px;
    align-items: center;
    justify-content: center;
    background-color: #C6C6C6;
    position: relative;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
}

.dropdown:hover .dropdown-content {
    display: block;
}