.focalmechanism-container {
    display: grid;
    grid-template-rows: repeat(4, auto);
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    padding-bottom: 24px;
}

.focalmechanism-container-title {
    grid-column: 1 / span 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.focalmechanism-container-title span.title {
    font-family: IBM Plex Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
}

.focalmechanism-container-title .header-buttons {
    display: flex;
    gap: 8px;
}

.two-cols-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
}

.fm-message-container {
    display: block;
    height: 48px;
    background: #FFFFFF;
    box-sizing: border-box;
    border: 1px solid #F1C21B;
    border-radius: 2px;
    position: relative;
    grid-column: auto / span 2;
}

.fm-yellow-rectangle {
    position: absolute;
    top: 0px;
    width: 4px;
    height: 100%;
    left: 0px;
    background: #F1C21B;
}

.fm-message {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    height: 18px;
    left: 20px;
    position: absolute;
    top: calc(50% - 18px/2);
}

.fm-message .warning {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.16px;
    color: #161616;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.fm-message .text {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.16px;
    color: #525252;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.fm-close {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 14px;
    top: calc(50% - 20px/2);
    cursor: pointer;
}

/* cards */
.fm-card {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 16px;
    gap: 10px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 2px;
}

.fm-card-title {
    min-height: 22px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #161616;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fm-card-subtitle {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #161616;
}

.fm-card-content {
    display: grid;
    grid-template-columns: 1fr;
}

.fm-card-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-top: 1px solid #e0e0e0;
    column-gap: 16px;
    padding: 16px 0px
}

.fm-card-row.title {
    padding: 8px 0px;
    border: none;
}

.span-2 {
    grid-column: auto / span 2 !important;
}

.ellipse {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.psmeca-container {
    grid-column: auto / span 2;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 2px;
    padding: 8px 16px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.psmeca-container span {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #161616;
}

.psmeca-container .ghost {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 12px;
    height: 40px;
    right: 16px;
    border-radius: 2px;
}

.psmeca-container .ghost:hover {
    background-color: #e0e0e0;
    cursor: pointer;
}

.psmeca-container .ghost .inner {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px;
    gap: 10px;
    height: 24px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.psmeca-container .ghost .inner span{
    height: 16px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #FF832B;
    flex: none;
    order: 0;
    flex-grow: 0;
}

/* palla tdmt/rcmt */
.fm-card-content .ball{
    justify-self: center;
}

/* double row */
.double-row-container {
    grid-column: auto / span 2;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 2px;
    padding: 8px 16px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.double-row-container .inner {
    display: grid;
    grid-template-rows: auto auto;
    row-gap: 8px;
}

.double-row-container .inner span.title {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #161616;
}

.double-row-container .inner span.subtitle {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.16px;
    color: #161616;
}

.double-row-container .ghost {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 12px;
    height: 40px;
    right: 16px;
    border-radius: 2px;
}

.double-row-container .ghost:hover {
    background-color: #e0e0e0;
    cursor: pointer;
}

.double-row-container .ghost .inner {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px;
    gap: 10px;
    height: 24px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.double-row-container .ghost .inner span {
    height: 16px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #FF832B;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.fm-card-row.cols-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.pb-10 {
 padding-bottom: 10px;
}

.cds--accordion__item {
    border: none;
    padding: 0px;
}

.cds--accordion__title {
    margin: 0px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    /* identical to box height, or 129% */
    display: flex;
    align-items: center;
    letter-spacing: 0.16px;
    text-transform: uppercase;

/* Tokens/Core Tokens/text-01 */
color: #161616;
}

.cds--accordion__title::before {
    padding: 0px;
}

.cds--accordion__item:last-child {
    border: none;
}

.cds--accordion__heading:focus::before {
    border: none;
}

.cds--accordion__heading:focus-visible {
    outline: none;
}

.cds--accordion__content {
    padding: 0px;
    margin: 0px;
}