    .doublerow-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items:flex-start
}

.doublerow-title {
    width: 100%;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #525252;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.doublerow-content {    
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #161616;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.stationmagnitudes-title {
    font-size: 12px;
    line-height: 16px;
}

.stationmagnitudes-content {
    font-size: 16px;
    line-height: 22px;
}