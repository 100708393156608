.settings-page{
    padding: 24px;
    background-color: #f4f4f4;
    display: grid;
    grid-template-rows: 57px calc(100vh - 57px - 24px - 16px);
    gap: 16px;
}

.settings-table-container {
    display: grid;
    grid-template-rows: 48px calc(100% - 48px - 48px - 8px);
}