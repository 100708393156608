.headerbutton-container {
    border-left: 1px solid #E0E0E0;
    display: grid;
    overflow: hidden;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-gap: 0px;
}

.headerbutton-btn {
    margin: 0px;    
    padding: 0px;
    background-color: #FFFFFF;
    width: 56px;
    height: 56px;
    box-shadow: none !important;
}

.headerbutton-btn:hover {
    cursor: pointer;
    background-color: #fff;
}

.headerbutton-btn:focus {
    border: none;
}
