.loader-container {
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loader-container-small {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 24px;
}

.loader-loading {    
    height: 22px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #161616;
}

.loader-loading-circle {
    width: 54px;
    height: 54px;
}

.cds--loading__stroke {
    stroke: #FF832B;
    stroke-dashoffset: 52.527552;
}

.white {
    background-color: #FFFFFF;
}