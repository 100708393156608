.earthquakelist-container {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: auto 56px auto 1fr auto; /* why 2fr? */
    grid-gap: 0;
    background: #F4F4F4F4;
}

.earthquakelist-content {
    grid-column: 1 / span 16;
    position: relative;
    display: grid;
    grid-template-rows: 50% 50%;
}

/* table */
.earthquakelist-list {
    grid-row: 2 / span 1;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 34px 1fr;
}

/* map */
.earthquakelist-map {
    grid-row: 1 / span 1;
    overflow: hidden;
    position: relative;
}

.earthquake-marker {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 8px;
    width: max-content;
    height: 28px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 28px;
}

.earthquake-marker:hover {
    background: #161616;
}

.earthquake-marker-circle {
    height: 12px;
    width: 12px;
    background-color: #FF832B;
    border-radius: 50%;
    display: inline-block;
}

.earthquake-marker-text {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #161616;
}

.earthquake-marker:hover > .earthquake-marker-text {
    color: #FFFFFF;
}

.no-box {
    box-shadow: none !important;
    border: none !important;
}

.no-box:focus {
    box-shadow: none !important;
    border: none !important;
}

.cell {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.16px;
    color: #161616;
}

.vertical {
    display: flex;
    padding: 0px;
    margin: 0px;
    height: 36px;
    flex-direction: column;
    align-items: stretch;
}

.cta-container {
    position: absolute;
    width: 278px;
    z-index: 40;
}

.group-footer-hidden {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: #16161680;
    z-index: 89;
}

.group-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: #fff;
    z-index: 999;
    height: 48px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
}

.group-footer .counter {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    position: relative;    
    height: 16px;
    left: 16px;
    top: calc(50% - 16px/2);
}

.group-footer .counter span {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.32px;
    color: #393939;
    flex: none;
    order: 0;
    flex-grow: 0;    
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.group-footer .confirm {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 8px;
    position: absolute;
    height: 32px;
    top: calc((48px - 32px) / 2);
    right: 8px;
}

.group-footer .confirm span {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.32px;
    color: #161616;
    flex: none;
    order: 0;
    flex-grow: 0;    
}

.group-footer .confirm span.icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px;
    gap: 12px;
    width: 32px;
    height: 32px;
    border-radius: 2px;
    flex: none;
    order: 2;
    flex-grow: 0;    
    cursor: pointer;
}

.group-footer .confirm span:hover.icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px;
    gap: 12px;
    width: 32px;
    height: 32px;
    border-radius: 2px;
    flex: none;
    order: 2;
    flex-grow: 0;    
    cursor: pointer;
    background-color: #e0e0e0;
}

.circular-marker-small {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #FF832B;
    border: 1px solid #16161680;
}


.circular-marker-small-hover {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #4C4C4C;
    border: 1px solid #16161680;
}

.circular-marker-medium {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #FF832B;
    border: 1px solid #16161680;
}

.circular-marker-medium-hover {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #4C4C4C;
    border: 1px solid #16161680;
}

.circular-marker-big {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #FF832B;
    border: 1px solid #16161680;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circular-marker-big-hover {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #4C4C4C;
    border: 1px solid #16161680;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circular-marker-verybig {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #FF832B;
    border: 1px solid #16161680;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circular-marker-verybig-hover {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #4C4C4C;
    border: 1px solid #16161680;
    display: flex;
    align-items: center;
    justify-content: center;
}


.circular-marker-small:hover{
    background: #4C4C4C;
    cursor: pointer;
}

.circular-marker-medium:hover{
    background: #4C4C4C;
    cursor: pointer;
}

.circular-marker-big:hover{
    background: #4C4C4C;
    cursor: pointer;
}

.circular-marker-big:hover .marker-text {
    color: #ffffff;
}

.circular-marker-verybig:hover {
    background: #4C4C4C;
    cursor: pointer;
}

.circular-marker-verybig:hover .marker-text {
    color: #ffffff;
}

.marker-text {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.32px;
    color: #161616;
    align-content: center;
}

.marker-text-hover {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.32px;
    color: #ffffff;
    align-content: center;
}

.query-result-header {
    height: 18px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    letter-spacing: 0.16px;
    color: #161616;
    flex: none;
    order: 2;
    margin-top: 16px;
    margin-bottom: 16px;
    flex-grow: 0;
}

.query-result-header span{
    color: #FF832B;
    margin-right: 4px;
}

.notification-eqlist {
    position: absolute;
    top: 8px;
    right: 16px;
    z-index: 90;
}