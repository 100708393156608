.custom-selector {
    width: 100%;
    min-height: 40px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 2px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.custom-selector:focus {
    width: 100%;
    min-height: 40px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 2px;
    box-sizing: border-box;
}

.custom-selector:disabled {
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border-radius: 2px;
    box-sizing: border-box;
}

.custom-selector div.input-text {
    width: calc(100%);
    display: flex;
    min-height: 40px;
}

.custom-selector div.cont {
    width: calc(100% - 40px);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    min-height: 40px;
    gap: 4px;
}

.custom-selector div.cont span {
    font-family: 'IBM Plex Sans';
    font-style: normal; 
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #161616;
}

.custom-selector:disabled span {
    color: #C6C6C6;
}

.custom-selector div.cont .arrow {
    width: 8px;
    position: absolute;
    right: 18px;
    top: 18px;
    bottom: 18px;
}

/* WHEN FIXED */
.custom-selector-content {
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 2px;
    width: 100%;
    box-sizing: border-box;
    z-index: 33;
    display: block;
    max-height: 200px;
    overflow-y: auto;
}

.custom-selector-content div {
    background: #FFFFFF;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    padding: 8px 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    letter-spacing: 0.16px;
    color: #525252;
}

.custom-selector-content div:hover {
    background-color: #E5E5E5;
    cursor: pointer;
}

.custom-selector-content div:active {
    background-color: #e5e5e5;
    background: #E5E5E5;
    border: 2px solid #FF832B;
    cursor: pointer;
}

.custom-selector-content div.active {
    background-color: #e5e5e5;
    background: #E5E5E5;
    cursor: pointer;
}

/* WHEN ABSOLUTE */
.custom-selector-content-absolute {
    position: absolute;
    top: 40;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 2px;
    width: 100%;
    box-sizing: border-box;
    z-index: 33;
    display: block;
    max-height: 200px;
    overflow: hidden auto;
}

.custom-selector-content-absolute div {
    background: #FFFFFF;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    padding: 8px 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    letter-spacing: 0.16px;
    color: #525252;
}

.custom-selector-content-absolute div:hover {
    background-color: #E5E5E5;
    cursor: pointer;
}

.custom-selector-content-absolute div:active {
    background-color: #e5e5e5;
    background: #E5E5E5;
    border: 2px solid #FF832B;
    cursor: pointer;
}

.custom-selector-content-absolute div.active {
    background-color: #e5e5e5;
    background: #E5E5E5;
    cursor: pointer;
}

.checkbox {
    margin-right: 12px;
    cursor: pointer;
}

.multi-select-item {
    display: flex;
    padding: 4px 16px !important;
}