.filter-section-inputbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
}

.filters-id-inputtext-label {
    color: #525252;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: IBM Plex Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.32px;
}