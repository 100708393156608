.mw-content {
    padding: 16px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 24px;
    background-color: #F4F4F4;
    border: 1px solid #E0E0E0
}

.row-mw-content {
    padding: 16px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 24px;
}