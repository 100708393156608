.magnitude-modal {
    position: fixed;
    top: 0px;
    width: 80%;
    right: 0px;
    border: none;
    outline: none;
    height: 100%;
    background-color: #f4f4f4;
    z-index: 90;
    display: grid;
    grid-template-rows: 64px 88px 70px calc(100% - 64px - 88px - 70px);
}

.focalmachanism-modal {
    position: fixed;
    top: 0px;
    width: 80%;
    right: 0px;
    border: none;
    outline: none;
    height: 100%;
    background-color: #f4f4f4;
    z-index: 90;
    display: grid;
    grid-template-rows: 64px calc(100% - 64px);
}

.magnitude-overlay {
    cursor: pointer;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #0008;
    z-index: 90;
}

.modal-switch {
    display: flex;
    height: 64px;
    padding: 8px 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid var(--Tokens-Core-Tokens-ui-03, #E0E0E0);
    background: #FFF;
}

.modal-switch .content-switcher-container {
    display: flex;
    height: 40px;
    align-items: flex-start;
}

.modal-switch .content-switcher-container .content-switcher {
    display: flex;
    align-items: center;
    gap: 8px;
}

.modal-switch .content-switcher-container .content-switcher .command {
    display: flex;
    width: 40px;
    height: 40px;
    padding: 8px;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    color: #e5e5e5;
}

.command:hover {
    background-color: #e5e5e5;
    color: #525252;
    cursor: pointer;
}

.modal-switch .content-switcher-container .content-switcher .header {
    color: var(--Tokens-Core-Tokens-text-01, #161616);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Headlines/Productive heading 02 */
    font-family: IBM Plex Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    /* 137.5% */
}

.modal-switch .close {
    display: flex;
    width: 40px;
    height: 40px;
    padding: 8px;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    border-radius: 2px;
}

.close:hover {
    background-color: #e5e5e5;
    cursor: pointer;
}

.modal-header {
    padding: 42px 0px 16px 24px;
    /* top right bottom left*/
    border-bottom: 1px solid #e0e0e0;
    position: relative;
    background-color: #fff;
}

.modal-header span {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #161616;
    margin: 8px 0px;
}

.modal-header div.ghost {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 8px;
    gap: 12px;
    position: absolute;
    width: 40px;
    height: 40px;
    right: 0px;
    top: 8px;
    border-radius: 2px;
}

.modal-header div.ghost:hover {
    background-color: #e0e0e0;
    cursor: pointer;
}

.modal-header div.ghost .inner {
    width: 24px;
    height: 24px;
    flex: none;
    order: 0;
    flex-grow: 0;
    position: relative;
}

.modal-header div.ghost .inner .icon {
    position: absolute;
    width: 14px;
    height: 14px;
    top: 5px;
    left: 5px;
}

.modal-resume {
    padding: 24px 0px 24px 24px;
    /* top right bottom left*/
    background-color: #fff;
    display: grid;
    grid-template-columns: repeat(5, minmax(0, auto)) 56px;
}

.modal-resume .starred {
    border-radius: 2px;
    background: var(--Tokens-Core-Tokens-interactive-02, #393939);
    width: 40px;
    height: 40px;
    padding: 8px;
}

.modal-container {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.fm-modal-container {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 24px;
    padding-bottom: 0;
}

/* START Magnitude Detail station magnitudes table */
/* header */
.station-magnitudes {
    background-color: #E0E0E0;
    padding: 0 16px;
    height: 48px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr)) minmax(0, 2fr) repeat(2, minmax(0, 1fr)) minmax(0, 2fr) repeat(1, minmax(0, 1fr));
    border-bottom: 1px solid #e0e0e0;
}

/* body */
.row-station-magnitudes {
    background-color: #f4f4f4;
    padding: 16px;
    padding-top: 24px;
    padding-bottom: 8px;
    height: 68px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr)) minmax(0, 2fr) repeat(2, minmax(0, 1fr)) minmax(0, 2fr) repeat(1, minmax(0, 1fr));
    border-bottom: 1px solid var(--color-e0);
}

.row-station-magnitudes:hover {
    background-color: #e0e0e0;
}

/* END Magnitude Detail station magnitudes table */

.station-magnitudes-header-container {
    display: flex;
    height: 70px;
    padding: 24px 1051px 24px 24px;
    align-items: center;
}

.station-magnitudes-header {
    color: var(--Tokens-Core-Tokens-text-01, #161616);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Headlines/Productive heading 02 */
    font-family: IBM Plex Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    /* 137.5% */
}