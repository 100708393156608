.logout-container {
    border-left: 1px solid #E0E0E0;
    width: 56px;
    height: 56px;
    flex: none;
    flex-grow: 0;
    outline: none;
}

.logout-btn {
    left: 6px;
    top: 8px;
    background-color: #FFFFFF;
    box-shadow: none !important;
}

.logout-btn:hover {
    cursor: pointer;
    background-color: #fff;
}

.logout-btn:focus {
    border: none;
}
