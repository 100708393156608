
.arrival-list {   
    display: grid;
    grid-template-rows: auto 1fr;
}

/* START OriginDetail arrivals table */
/* header */
.arrivals {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 8px;
    display: grid;
    grid-template-columns: 10% 10% 6% 7% 9% 4% 4% 7% 8% 8% 8%;
    column-gap: 24px;
    border-bottom: 1px solid #e0e0e0;
}

/* row */
.row-arrivals {
    padding: 24px 16px;
    display: grid;
    grid-template-columns: 10% 10% 6% 7% 9% 4% 4% 7% 8% 8% 8%;
    column-gap: 24px;
    border-bottom: 1px solid #e0e0e0;
}

.row-arrivals:hover {
    background-color: #e0e0e0;
    cursor: pointer;
}
/* END OriginDetail arrivals table */