.origin-detail-container {
    padding: 24px;
    background: #F4F4F4;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
}

.origin-detail {
    display: grid;
    grid-template-rows: 57px repeat(4, 74px) calc(100vh - 56px - 57px - 296px - 24px);
}

.origin-detail-header {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid var(--Tokens-Core-Tokens-stroke, #E0E0E0);
    padding: 16px 0;
}

.origin-detail-tabs {
    padding-top: 24px;
    display: grid;
    grid-template-rows: 48px calc(100% - 48px);
}


/* old css */
.origindetail-container {
    padding: 24px;
    background: #F4F4F4;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
}

.origindetail-info-row {
    display: grid;
    overflow: hidden;
    grid-template-columns: repeat(16, 1fr);
    column-gap: 24px;
    row-gap: 24px;
    padding-bottom: 12px;
    margin-top: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid #E0E0E0;
    -ms-overflow-style: none;
    /* Hide scrollbar for IE and Edge */
    scrollbar-width: none;
    /* Hide scrollbar forFirefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.earthquake-detail::-webkit-scrollbar {
    display: none;
}

.cds--tab-content {
    padding: 0;
}

.cols-4 {
    grid-column: auto / span 4;
}



.origin-detail .provenance-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    margin-top: 4px;
}

.origin-detail .provenance-container .provenance {
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    border-radius: 2px;
    background: var(--Tokens-Core-Tokens-ui-04, #8D8D8D);
}

.origin-detail .provenance-container .provenance .label {
    color: var(--Tokens-Core-Tokens-inverse-01, #FFF);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: IBM Plex Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    /* 120% */
    letter-spacing: 0.32px;
}