.dropdown-download-container {
    background-color: #fff;
    color: #525252;
    width: 177px;
    min-height: 178px;
    margin-top: 8px;
    z-index: 50;
}

.download-title {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
    color: #161616;
}

.download-item {
    height: 32px;
    width: 100%;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px 16px 8px 16px;
}

.download-item:hover {
    background-color: #E5E5E5;
}