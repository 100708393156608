.switcher {
    border-left: 1px solid #E0E0E0;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.dropdown-language-56 {
    z-index: 3;
    position: fixed;
    border-radius: 2px;
    top: 56px;
    right: 0px;
}   

.dropdown-language-container {
    background-color: #fff;
    padding: 12px;
    color: #525252;
    width: 56px;
    height: 100%;
    margin-top: 8px;
}

.centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.selected-language {
    cursor: pointer;
    color: #525252;
    padding-top: 3px;
    width: 24px;
    height: 24px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.16px;
    align-self: center;
    text-align: center;
    text-transform: uppercase;
}

.language-item {
    height: 32px;
    width: 100%;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px 16px 8px 16px;
    align-self: center;
    text-align: center;
}

.language-item:hover {
    background-color: #E5E5E5;
}