.navbar {
    display: grid;
    overflow: hidden;
    grid-template-columns: 56px 1fr 56px 56px 56px;
    grid-gap: 0px;
}

.navbar-admin {
    display: grid;
    overflow: hidden;
    grid-template-columns: 56px 1fr 56px 56px 56px 56px;
    grid-gap: 0px;
}

.navbar h1 {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #161616;
    margin-top: 16px;
    margin-left: 16px;
    margin-bottom: 16px;
}

.navbar-admin h1 {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #161616;
    margin-top: 16px;
    margin-left: 16px;
    margin-bottom: 16px;
}