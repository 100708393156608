.city-list {
    height: 100%;
    overflow-y: auto;
    background-color: white;
}

.vertical-20 {
    display: flex;
    padding: 0;
    margin: 0;
    height: 20px;
    flex-direction: column;
    align-items: stretch;
}

.no-cities-container {
    display: flex;
    height: calc(50vh);
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.no-cities-title {
    width: 332px;
    height: 28px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #161616;
}

.no-cities-text {
    width: 100%;
    height: 100px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 0.16px;
    color: #525252;
    margin-top: 16px;
    margin-left: 48px;
    margin-right: 48px;
}

/* START EventDetail cities table */
/* header */
.cities {
    padding: 0 16px;
    height: 48px;
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    border-bottom: 1px solid #e0e0e0;
}

/* row */
.row-cities {
    border-top: 1px solid var(--color-e0);
    padding: 16px;
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
}

.row-cities:first-child {
    border-top: none;
}

.row-cities:last-child {
    border-bottom: 1px solid var(--color-e0);
}

.row-cities:hover {
    background-color: #e0e0e0;
    cursor: pointer;
}

/* END EventDetail cities table */

.citytable-header {
    margin-top: 32px;
    padding-bottom: 8px;
    padding-left: 16px;
    border-bottom: 1px solid #e0e0e0;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.16px;
    text-transform: uppercase;
    color: #161616;
}