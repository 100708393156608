.dropdown-menu-container {
    background-color: #fff;
    padding: 24px;
    color: #525252;
    width: 472px;
    max-height: 712px;
    margin-top: 8px;
}

.dropdown-menu-48 {
    z-index: 35;
    position: absolute;
    border-radius: 2px;
    top: 40px;
    right: 0px;
    border: 1px solid #e0e0e0;
}

.dropdown-date-container {
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 11px;
    padding-bottom: 11px;
    border: 1px solid #E0E0E0;
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
}

.datetime {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.mg-label-container {
    display: flex;
}

.mg-label {
    flex-grow: 1;
}

.overflowed {
    height: 424px;
    overflow-y: auto;
    padding-top: 24px;
    overflow-x: hidden;
}

.button-container {
    z-index: 3;
    display: flex;
    justify-content: space-between;
}

.button {
    min-width: 95px;
    height: 40px;
    text-transform: uppercase;
}

.remove-button {
    height: 40px;
    text-transform: uppercase;
}

option {
    background-color: #abc;
}