/*/////////////////////// MODAL  */
.cds--modal-close {
  display: none;
}

.cds--modal-close:focus, .cds--modal-close:active {
  outline: none;
  border:none;
}

.cds--modal-close {
  background-color: #abc;
}

.cds--modal-header {
  display: none;
}

.cds--modal-content {
  margin: 0px;
  padding: 0px;
}

.bea-modal-container {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 16px;
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  border-radius: 2px;
}

.bea-modal-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  order: 0;
  width: 100%;
}

.bea-modal-text-container span.title {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #161616;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.bea-modal-text-container span.text {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: #525252;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.bea-modal-container .bea-modal-button-container {
  margin-top: 24px;
  width: 218px;
  height: 40px;
  flex: none;
  order: 1;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}

.modal-loader-loading-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 48px;
}

.cds--loading__stroke {
  stroke: #FF832B;
  stroke-dashoffset: 52.527552;
}