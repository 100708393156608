.container-8 {
    grid-column: auto / span 8;
}

.container-12 {
    grid-column: auto / span 12;
}

.container-16 {
    grid-column: auto / span 16;
}

.cols-2 {
    grid-column: auto / span 2;
}

.cols-3 {
    grid-column: auto / span 3;
}

.cols-5 {
    grid-column: auto / span 5;
}

.cols-6 {
    grid-column: auto / span 6;
}

/* START unauthenticated table */
/* header unauthenticated list */
.public {
    height: 34px;
    display: grid;
    grid-template-columns: 11% 9% 1fr 10% 3% 9% 11% 9% 36px;
    column-gap: 24px;
    padding-right: 8px;
    padding-left: 16px;
    border-bottom: 1px solid var(--color-e0);
}

/* row unauthenticated list */
.row-public {
    border-top: 1px solid var(--color-e0);
    display: grid;
    grid-template-columns: 11% 9% 1fr 10% 3% 9% 11% 9% 36px; 
    column-gap: 24px;
    padding: 8px 8px 8px 16px;
    cursor: pointer;
    background-color: #FFFFFF;
}

.row-public:hover {
    background-color: #e5e5e5;
}

.row-public:first-child {
    border-top: none;
}
/* END unauthenticated table */

/* START private events-group table */
/* header private events-group list */
.events-group-route {
    height: 34px;
    display: grid;
    grid-template-columns: 32px 7% 10% 7% 1fr 9% 3% 5% 8% 7% 20px 36px;
    column-gap: 16px;
    padding-right: 8px;
    border-bottom: 1px solid var(--color-e0);
}

/* row private events-group list */
.row-events-group-route {
    border-top: 1px solid var(--color-e0);
    padding-top: 8px;
    padding-bottom: 8px;
    display: grid;
    grid-template-columns: 32px 7% 10% 7% 1fr 9% 3% 5% 8% 7% 20px 36px;
    column-gap: 16px;
    padding-right: 8px;
    cursor: pointer;
    background-color: #FFFFFF;
}

.row-events-group-route:first-child {
    border-top: none;
}

.row-events-group-route-child {
    padding-top: 8px;
    padding-bottom: 8px;
    display: grid;
    grid-template-columns: 32px 7% 10% 7% 1fr 9% 3% 5% 8% 7% 20px 36px;
    column-gap: 16px;
    padding-right: 8px;
    cursor: pointer;
    background-color: #FFFFFF;
}

.row-events-group-route:hover {
    background-color: #e5e5e5;
}

.row-events-group-route-child:hover {
    background-color: #e5e5e5;
}
/* END private events-group table */

/* START private others table */
/* header private others list */
.all-route {
    height: 34px;
    display: grid;
    grid-template-columns: 7% 10% 8% 1fr 9% 3% 7% 10% 9% 36px;
    column-gap: 16px;
    padding-right: 8px;
    padding-left: 24px;
    border-bottom: 1px solid var(--color-e0);
}

/* row private others list */
.row-all-route {
    border-top: 1px solid var(--color-e0);
    display: grid;
    grid-template-columns: 7% 10% 8% 1fr 9% 3% 7% 10% 9% 36px;
    column-gap: 16px;
    padding: 8px 8px 8px 24px;
    cursor: pointer;
    background-color: #FFFFFF;
}

.row-all-route:first-child {
    border-top: none;
}

.row-all-route:hover {
    background-color: #e5e5e5;
}
/* END private others table */

.header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 0;
}

.empty-header-container {
    width: 24px !important;
    height: 24px !important;
}

.open-group {
    height: 36px !important;
    padding-left: 16px;
}

.sortable {
    cursor: pointer;
} 

.header-text {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #161616;
    flex-grow: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 4px;
}

.cell-text {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #161616;
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.row-cell-text {  
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.16px;
    color: #161616;
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;    
}

.border-bottom {
    border-bottom: 1px solid var(--color-e0);
}

/* START magnitude row coloring */
.earthquakelist-medium-eq {
    background: #FCF4D6;
}

.earthquakelist-medium-eq:hover {
    background: #F8E0A0;
    cursor: pointer;
}

.earthquakelist-medium-eq:active {
    background: #F8E0A0;
    cursor: pointer;
}

.earthquakelist-big-eq {
    background: #FFD7D9;
}

.earthquakelist-big-eq:hover {
    background: #FF99A0;
    cursor: pointer;
}

.earthquakelist-big-eq:active {
    background: #FF99A0;
    cursor: pointer;
}

.earthquakelist-verybig-eq {
    background: #FFB3B8;
}

.earthquakelist-verybig-eq:hover {
    background: #FF99A0;
    cursor: pointer;
}

.earthquakelist-verybig-eq:active {
    background: #FF99A0;
    cursor: pointer;
}
/* END magnitude row coloring */

.heartquake-divider-trailer {
    grid-column: auto / span 2;
    border-bottom: 1px solid var(--color-e0);
}

.heartquake-divider-trailer.earthquakelist-medium-eq {
    grid-column: auto / span 2;
    border-bottom: 1px solid #FCF4D6;
}

.heartquake-divider-trailer.earthquakelist-medium-eq:hover {
    grid-column: auto / span 2;
    border-bottom: 1px solid #F8E0A0;
}

.heartquake-divider-trailer.earthquakelist-verybig-eq {
    grid-column: auto / span 2;
    border-bottom: 1px solid #FFB3B8;
}

.heartquake-divider-trailer.earthquakelist-verybig-eq:hover {
    grid-column: auto / span 2;
    border-bottom: 1px solid #FFB3D8;
}

.heartquake-divider-trailer.earthquakelist-big-eq {
    grid-column: auto / span 2;
    border-bottom: 1px solid #FFD7D9;
}

.heartquake-divider-trailer.earthquakelist-big-eq:hover {
    grid-column: auto / span 2;
    border-bottom: 1px solid #FF99A0;
}

.single-row-container {
    display: flex;
    flex-direction: row;
    overflow: hidden;    
}

.single-row-content {    
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.16px;
    color: #161616;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.container-divider {
    display: grid;
    grid-template-columns: repeat(35, 1fr);
}

.heartquake-divider-header {
    grid-column: 3 / span 35;
    border-bottom: 1px solid var(--color-e0);
}

.preferred {
    border-left: 4px solid #FF832B;
}

.cta-btn {
    position: static;
    height: 36px;
    background-color: #00000000;
    color: #FF832B;
    box-shadow: none !important;
    display: flex;
    border-radius: 2px;
    text-align: center;
    justify-content: center;
    align-items: center;
}
  
.cta-btn:hover {
    background-color: #c6c6c6;
    border-radius: 2px;
    color: #EB6200;
    font-style: normal;
    text-align: center;
    justify-content: center;
}
  
.cta-btn:focus {
    background-color: #00000000;
    border-radius: 2px;
    border: none;
    color: #FF832B;
    text-align: center;
    justify-content: center;
}

.cta-btn.active{
    background-color: #C6C6C6;
    border: none;
    color: #BA4E00;
    text-align: center;
    justify-content: center;
}

.cta-btn:active {
    background-color: #C6C6C6;
    border: none;
    color: #BA4E00;
    text-align: center;
    justify-content: center;
}