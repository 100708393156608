/* roba che sta qua ma non dovrebbe esserci */
.magnitudes-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
}

.magnitudes-list span.mag {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;
  height: 20px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.magnitudes-list span.mag .icon {
  width: 6px;
  height: 6px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.magnitudes-list span.mag .text {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.16px;
  color: #161616;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.magnitudes-list div.more-magnitudes {
  width: 20px;
  height: 20px;
  background: #8D8D8D;
  border-radius: 2px;
  flex: none;
  order: 1;
  flex-grow: 0;
  padding-left: 3px;
}

.magnitudes-list div.more-magnitudes span {
  width: 20px;
  height: 20px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
}

/* FINE roba che sta qua e non dovrebbe esserci */



/* a new world is possible */
.origin-table-container {
  background: white;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  height: 100%;
}

.origin-button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
  gap: 24px;
}

.origin-table {
  overflow: auto;
  width: 100%;
}

.route-container {
  background: white;
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  height: 100%;
  padding: 32px 24px 32px 24px;
}

.route-body {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.route-body div.title span {
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.1599999964237213px;
  text-align: left;

}

.route-body div.option {
  display: flex;
  flex-direction: column;
}

.route-body div.option div.routetext {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 24px;
  gap: 4px;
}

.route-body div.option div.routetext svg:hover {
  cursor: pointer;
}

/* event detail grid layout*/
/* origin table */
.ed-ot-grid {
  grid-template-columns: repeat(4, minmax(0, 1fr)) 48px repeat(4, minmax(0, 1fr)) 40px 40px 40px;
}

.ed-ot-pub-grid {
  grid-template-columns: repeat(4, minmax(0, 1fr)) 48px repeat(4, minmax(0, 1fr)) 40px 48px;
}

/* magnitude table */
.ed-mt-pub-grid {
  grid-template-columns: repeat(2, minmax(0, 1fr)) 80px 80px repeat(3, minmax(0, 1fr)) 64px;
}

.ed-mt-grid {
  grid-template-columns: repeat(2, minmax(0, 1fr)) 80px 80px repeat(3, minmax(0, 1fr)) 64px 56px;
}

/* fault plane solution table */
.ed-fps-grid {
  grid-template-columns: repeat(2, minmax(0, 1fr)) 72px repeat(3, minmax(0, 1fr)) 64px repeat(2, minmax(0, 1fr)) 56px 40px 40px;
}

.ed-fps-pub-grid {
  grid-template-columns: repeat(2, minmax(0, 1fr)) 72px repeat(3, minmax(0, 1fr)) 64px repeat(2, minmax(0, 1fr)) 56px 48px;
}

/* origin detail grid layout*/
/* magnitude table */
.od-mt-grid {
  grid-template-columns: repeat(6, minmax(0, 1fr)) 64px 64px;
}

.od-mt-pub-grid {
  grid-template-columns: repeat(6, minmax(0, 1fr)) 64px;
}

/* arrival table */
.od-at-grid {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}


.od-fps-grid {
  grid-template-columns: repeat(6, minmax(0, 1fr)) 64px repeat(1, minmax(0, 1fr)) 56px 40px 40px;
}

.od-fps-pub-grid {
  grid-template-columns: repeat(6, minmax(0, 1fr)) 64px repeat(1, minmax(0, 1fr)) 56px 48px;
}

.od-pr-grid {
  grid-template-columns: 96px minmax(0, 1fr);
}

/* settings */
/* event type table */
.sp-et-grid {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

/* origin type table */
.sp-ot-grid {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

/* magnitude type table */
.sp-mt-grid {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

/* localspace table */
.sp-ls-grid {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

/* provenance table */
.sp-pr-grid {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

/*  end grids defintion*/
.origin-table .ot-table .ot-row {
  display: grid;
}

.origin-table .ot-table .ot-header {
  background: var(--Tokens-Core-Tokens-ui-02, #F4F4F4);
  position: sticky;
  top: 0;
  z-index: 30;
}

.origin-table .ot-table .ot-body {
  border-bottom: 1px solid var(--Tokens-Core-Tokens-stroke, #E0E0E0);
}

.selected {
  background-color: #e5e5e5;
}

.origin-table .ot-table .ot-body:hover {
  cursor: pointer;
  background-color: #e5e5e5;
}

.origin-table .ot-table .ot-header .ot-th {
  background: var(--Tokens-Core-Tokens-ui-02, #F4F4F4);
  display: flex;
  height: 48px;
  padding: 0px 16px;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  width: 100%;
}

.ot-th-p-08 {
  padding: 0px 8px !important;
}

.ot-td-p-08 {
  padding: 16px 8px !important;
}

.origin-table .ot-table .ot-header .ot-th span {
  color: var(--Tokens-Core-Tokens-text-01, #161616);
  font-feature-settings: 'clig' off, 'liga' off;

  /* Headlines/Productive heading 01 */
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 128.571% */
  letter-spacing: 0.16px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.origin-table .ot-table .ot-header .ot-th .icon {
  display: flex;
  width: 16px;
  height: 16px;
  padding: 2px 4.505px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.ot-th:hover {
  cursor: pointer;
}

.origin-table .ot-table .ot-body .ot-td {
  display: flex;
  max-height: 68px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  width: 100%;
}

.origin-table .ot-table .ot-body .ot-td a {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  width: 100%;
}

.origin-table .ot-table .ot-body .ot-td a span {
  color: var(--Tokens-Core-Tokens-text-01, #161616);

  /* Bodies/Body long 01 */
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.16px;
  width: 100%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.origin-table .ot-table .ot-body .ot-td a span.link {
  color: #FF832B;
}

.origin-table .ot-table .ot-body .ot-td a .subtitle {
  display: flex;
  align-items: baseline;
  gap: 4px;
  width: 100%;
}

.origin-table .ot-table .ot-body .ot-td a .subtitle .preferred {
  width: 8px;
  height: 8px;
  background-color: #FF832B;
  border-radius: 50%;
}

.origin-table .ot-table .ot-body .ot-td a span .preferred {
  width: 8px;
  height: 8px;
  background-color: #FF832B;
  border-radius: 50%;
}

.origin-table .ot-table .ot-body .ot-td a .subtitle span {
  color: var(--Tokens-Core-Tokens-text-02, #525252);

  /* Other styles/Helper text 01 */
  font-family: IBM Plex Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
  letter-spacing: 0.32px;

  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.origin-table .ot-table .ot-body .ot-td a div.magnitude {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  width: 100%;
}

.origin-table .ot-table .ot-body .ot-td a div.magnitude div {
  display: flex;
  align-items: center;
  gap: 4px;
}

.origin-table .ot-table .ot-body .ot-td a div.magnitude div .preferred {
  width: 8px;
  height: 8px;
  background-color: #FF832B;
  border-radius: 50%;
}

.origin-table .ot-table .ot-body .ot-td a div.magnitude div.subtitle span {
  color: var(--Tokens-Core-Tokens-text-02, #525252);

  /* Other styles/Helper text 01 */
  font-family: IBM Plex Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
  letter-spacing: 0.32px;

  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.preferred-label-container {
  display: inline-flex;
  align-items: baseline;
  gap: 6px;
  margin: 16px;
  margin-right: 0;
}

.preferred-label-container .indicator {
  width: 8px;
  height: 8px;
  background-color: #FF832B;
  border-radius: 50%;
}

.preferred-label-container .label {
  color: var(--Tokens-Core-Tokens-text-01, #161616);
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: IBM Plex Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 133.333% */
  letter-spacing: 0.32px;
}

.ot-cta-container {
  display: flex;
  padding: 8px 0;
  align-items: center;
  gap: 12px;
  border-radius: 2px;
  position: relative;
}

.ot-cta-container:hover {
  background: var(--Tokens-Interactive-Tokens-active-ui, #C6C6C6);
}

.ot-cta-container-active {
  width: 24px;
  height: 40px;
  display: flex;
  padding: 8px 0;
  align-items: center;
  gap: 12px;
  border-radius: 2px;
  position: relative;
  background: var(--Tokens-Interactive-Tokens-active-ui, #C6C6C6);
}

.ot-cta-menu {
  position: absolute;
  right: 0;
  z-index: 50;
  margin: 0;
  padding: 0;
}

.ot-cta-down {
  top: 44px
}

.ot-cta-up {
  top: -86px;
}

.ot-origin {
  width: 217px;
}

.ot-magnitude {
  width: 278px;
}

.ot-focalmechanism {
  width: 301px;
}

.ot-options-container {
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  background-color: #ffffff;
  padding: 4px 0px;
  gap: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1000px;
  cursor: default;
}

.ot-options-container h4 {
  padding: 8px 16px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.32px;
  color: #161616;
  flex: none;
  order: 0;
  flex-grow: 0;
  text-transform: capitalize;
}

.ot-options-container .divider-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  max-width: 100%;
  height: 1px;
  margin: 0px 16px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.ot-options-container .divider {
  height: 1px;
  background: #E0E0E0;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.ot-options-container .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 16px 9px 16px;
  height: 32px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

.ot-options-container .item-no-click {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 16px 9px 16px;
  height: 32px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

.ot-options-container .item:hover {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 16px 9px 16px;
  height: 32px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  background: #E5E5E5;
  cursor: pointer;
}

.ot-options-container .item-no-click:hover {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 16px 9px 16px;
  height: 32px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  cursor: default !important;
}

.ot-options-container .item p {
  height: 16px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.32px;
  color: #393939;
  flex: none;
  order: 0;
  flex-grow: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ot-options-container .item-no-click p {
  height: 16px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.32px;
  color: #393939;
  flex: none;
  order: 0;
  flex-grow: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.origin-table .ot-table .ot-body .ot-td a div.provenance {
  display: flex;
  padding: 8px 0px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
}


.origin-table .ot-table .ot-body .ot-td a div.provenance span.provenance {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  background-color: #8D8D8D;
  color: var(--Tokens-Core-Tokens-inverse-01, #FFF);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: IBM Plex Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  /* 120% */
  letter-spacing: 0.32px;
  border-radius: 2px;
}

.dropdown-provenance {
  z-index: 35;
  position: absolute;
  border-radius: 2px;
  top: 48px;
  right: 0px;
  width: 316px;
}