.create-event-container {
    height: calc(100vh - 56px);
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: 2fr;
    grid-gap: 0px;
    background: #F4F4F4F4;
}

.create-event-content {
    grid-column: 1 / span 11; /* this is to fill the parent */
    display: grid; /* this is for the children */
    grid-template-rows: auto 1fr;
    row-gap: 24px;
    padding: 24px;
}

.create-event-map {
    grid-column: 12 / span 16; /* this is to fill the parent */
}

.create-event-rows {
    display: grid;
    height: calc(100vh - 56px - 57px - 50px);
    /* grid-template-columns: repeat(8, 1fr); */
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: auto auto auto auto auto;
    /* four rows */
    column-gap: 16px;
    /* top right bottom left*/
    overflow-y: auto;
}

.create-box {
    background: #f4f4f4f4;
    /* this is to fill the parent */
    grid-column: auto / span 8; /* this is to fill the parent */
    /* grid-row: span 1; */
    border-bottom: 1px solid #e0e0e0;
    padding: 24px 0px 24px 0px;
}

.create-box:last-child {
    border: none;
}

.create-box-flex:last-child {
    border: none;
}

.create-box-flex {
    /* this is to fill the parent */
    grid-column: auto / span 8;
    /* grid-row: span 1; */
    border-bottom: 1px solid #e0e0e0;
    padding: 24px 0px 24px 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.create-box-flex h4 {
    width: 100%;
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.16px;
    color: #525252;
}

.create-box-flex .origin-row-form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;
}

.create-box-flex .origin-row-form.right {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;
    justify-content: flex-end;
}

.create-box-flex .two-columns {
    display: flex;
    gap: 4px;
    align-items: flex-start;
}

.create-box-flex .two-columns .form-item-big {
    display: grid;
    gap: 4px;
    grid-template-rows: auto auto;
    grid-column: auto;
    min-width: 240px;
}

.create-box-flex .form-item-big {
    display: grid;
    gap: 4px;
    grid-template-rows: auto auto;
    grid-column: auto / span 6;
    min-width: 240px;
}

.create-box-flex form-item-big {
    display: grid;
    gap: 4px;
    grid-template-rows: auto auto;
    grid-column: auto / span 6;
    min-width: 240px;
}

.create-box-flex .form-item-little {
    display: grid;
    gap: 4px;
    grid-template-rows: auto auto;
    grid-column: auto / span 2;
    width: 72px;
}

.create-box-flex .form-item-verylittle {
    display: grid;
    gap: 4px;
    grid-template-rows: auto auto;
    grid-column: auto / span 1;
    width: 32px;
}

.create-box-flex h4 {
    grid-column: auto / span 16;
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.16px;
    color: #525252;
}

.create-box-flex h5 {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.32px;
    color: #525252;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: 4px;
}

.create-fillerbox {
    background: #f4f4f4f4;
    /* this is to fill the parent */
    grid-column: auto / span 8;
    /* grid-row: span 1; */
    padding: 24px 0px 48px 0px;
}

.create-box h4 {
    grid-column: auto / span 16;
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.16px;
    color: #525252;
}

.create-box h5 {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.32px;
    color: #525252;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.create-box .form {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    column-gap: 16px;
}

.create-box .origin-row-form {
    display: grid;
    grid-template-columns: repeat(28, 1fr);
    column-gap: 8px;
}

.create-box .form-item-big {
    display: grid;
    gap: 4px;
    grid-template-rows: auto auto;
    grid-column: auto / span 6;
}

.create-box .form-item-verybig {
    display: grid;
    gap: 4px;
    grid-template-rows: auto auto;
    grid-column: auto / span 12;
}

.create-box .form-item-big8 {
    display: grid;
    gap: 4px;
    grid-template-rows: auto auto;
    grid-column: auto / span 8;
}
.create-box .form-item-medium {
    display: grid;
    gap: 4px;
    grid-template-rows: auto auto;
    grid-column: auto / span 4;
}
.create-box .form-item-little {
    display: grid;
    gap: 4px;
    grid-template-rows: auto auto;
    grid-column: auto / span 2;
}
.create-box .form-item-verylittle {
    display: grid;
    gap: 4px;
    grid-template-rows: auto auto;
    grid-column: auto / span 1;
}
.form-item-buttonSave {
    display: grid;
    gap: 4px;
    grid-template-rows: auto auto;
    grid-column: -1 / span 2;
    min-width: 95px;
}

.form-item-buttonCancel {
    display: grid;
    gap: 4px;
    grid-template-rows: auto auto;
    grid-column: -5 / span 2;
    min-width: 95px;
}

.create-box .form-item-big-GAP {
    display: grid;
    gap: 4px;
    grid-template-rows: auto auto;
    grid-column: 16 / span 6;
}

.newevent-map {
    overflow: hidden;
}

.origin-marker {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px;
    gap: 8px;
    width: 28px;
    height: 28px;
    position: relative;
    top: -8px;
    left: -8px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 28px;
}

.origin-marker-circle {
    height: 12px;
    width: 12px;
    background-color: #ff832b;
    border-radius: 50%;
    display: inline-block;
}

.button-undo {
    background: #393939;
    border-radius: 2px;
    align-items: center;
    padding: 12px;
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
}

.button-undo:focus,
.button-undo:active,
.button-undo:hover {
    background-color: #393939;
    outline: none !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
    border-width: 0px;
}

.button-save {
    background: #ff832b;
    border-radius: 2px;
    align-items: center;
    padding: 12px;
    font-family: "IBM Plex Sans";
    color: #393939;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
}

.button-save:focus,
.button-save:active,
.button-save:hover {
    background-color: #ff832b;
    color: #393939;
    outline: none !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
    border-width: 0px;
}

.bea-h3 {
    padding: 0px 8px 8px 0px;
}
.disabled-label {
    color: rgba(22, 22, 22, 0.25) !important;
}

.mt-1 {
    margin-top: 1px;
}

.mt-2 {
    margin-top: 2px;
}