.empty-list-container {
    display: flex;
    height: calc(50vh);
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
}

.empty-list-container-small {
    display: flex;
    height: calc(20vh);
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
}

.empty-list-container-verysmall {
    margin: 32px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
}

.empty-list-title {
    height: 28px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #161616;
}

.empty-list-description {
    height: 60px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.16px;
    color: #525252;
    margin-top: 16px;
}