.bea-toast {
    /* Toast */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 8px 16px;
    gap: 10px;

    position: absolute;
    height: 48px;
    right: 8px;
    top: 8px;

    /* Tokens/Core Tokens/inverse-02 */
    background: #393939;
    /* Tokens/Core Tokens/stroke */
    border-radius: 2px;
    border: 1px solid #E0E0E0;
}

.bea-toast-container {
    /* Frame 355 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 24px;

    height: 32px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

}

.bea-toast-container .label {
    margin: 0 auto;
    height: 18px;

    /* Bodies/Body short 01 */
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */
    display: flex;
    align-items: center;
    letter-spacing: 0.16px;

    /* Tokens/Core Tokens/text-04 */
    color: #FFFFFF;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.bea-toast-container .cta {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    margin: 0 auto;
    width: 107px;
    height: 32px;


    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

}

.bea-toast-container .cta .ghost {
    /* Ghost 32px / Only text */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px;
    gap: 12px;

    width: 79px;
    height: 32px;

    border-radius: 2px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.bea-toast-container .cta .cta-close {
    width: 20px;
    height: 20px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
    position: relative;
}

.bea-toast-container .cta .cta-close:hover {
    cursor: pointer;
    background: #636363;
}

.bea-toast-container .cta .cta-close svg {
    position: absolute;
    left: 20.83%;
    right: 20.83%;
    top: 20.83%;
    bottom: 20.84%;
}

.bea-toast-container .cta .ghost .button {
    /* Frame 5 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px;
    gap: 10px;

    width: 71px;
    height: 24px;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.bea-toast-container .cta .ghost .button .label {
    /* Label */

    width: 63px;
    height: 16px;

    /* Other styles/Button */
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */
    display: flex;
    align-items: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;

    /* Tokens/Core Tokens/link-01 */
    color: #FF832B;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.notification-eqlist {
    position: absolute;
    top: 8px;
    right: 16px;
    z-index: 90;
}

.notification-hover {
    position: absolute;
    top: 72px;
    right: 16px;
    z-index: 99;
}

.settings-notification {
    position: absolute;
    right: 16px;
    top: 72px;
    z-index: 9999;
}