.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3px 6px 3px 8px;
    gap: 8px;
    background: #525252;
    border-radius: 2px;
    cursor: pointer;
}

.text {
    color: #ffffff;
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.16px;
}