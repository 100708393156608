.interval-viewer-container {
    background: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-items: center;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    height: 40px;
    padding-right: 11px;
}

.interval-viewer {
    display: flex;
    width: 100%;
    align-items: center;
    height: fit-content;
    padding: 8px;
    letter-spacing: 0.16px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    white-space: pre;
    color: #161616;
    cursor: pointer;
}

.interval-viewer-disabled {
    color: rgba(22, 22, 22, 0.25)
}