.bc-refresh-btn {
    width: 40px;
    height: 40px;
    background-color: #393939;
    padding: 8px;
    border-radius: 2px;
}

.bc-refresh-btn:hover {
    background-color: #4C4C4C;
    cursor: pointer;
    padding: 8px;
}

.bc-refresh-btn.active {
    background-color: #4C4C4C;
    cursor: pointer;
    padding: 8px;
}

.bc-refresh-btn:focus {
    background-color: #393939;
    border: 2px solid var(--cds-button-primary);
    border-radius: 2px;
    padding: 6px;
}

.bc-refresh-btn:active {
    background-color: #6F6F6F !important;
    border: 1px solid var(--cds-button-primary);
}