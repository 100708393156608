.geofence-inputs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.geofence-input-text {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}