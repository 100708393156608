.earthquake-detail-container {
    display: grid;
    overflow: hidden;
    grid-template-columns: minmax(0, 70%) minmax(0, 30%);
    grid-template-rows: minmax(0, 1fr);
    grid-gap: 0;
    background: #f4f4f4;
}

.earthquake-detail {
    display: grid;
    grid-template-rows: 57px 100px calc(100vh - 57px - 100px - 56px - 24px);
    z-index: 25;
    padding: 24px;
    padding-bottom: 0px;
}

.earthquake-detail-table-container {
    display: grid;
    grid-template-rows: 48px calc(100% - 48px);
    padding-bottom: 2px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.earthquake-detail::-webkit-scrollbar {
    display: none;
}

.originlist-map {
    z-index: 1;
    overflow: hidden;
    position: relative;
}

.origin-marker {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px;
    gap: 8px;
    width: 28px;
    height: 28px;
    position: relative;
    top: -8px;
    left: -8px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 28px;
}

.origin-marker:hover {
    background: #161616;
}

.origin-marker-hover {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px;
    gap: 8px;
    width: 28px;
    height: 28px;
    position: relative;
    top: -8px;
    left: -8px;
    background: #161616;
    border: 1px solid #E0E0E0;
    border-radius: 28px;
}

.origin-marker-circle {
    height: 12px;
    width: 12px;
    background-color: #FF832B;
    border-radius: 50%;
    display: inline-block;
}

.origin-marker-star {
    height: 16px;
    width: 16px;
    display: inline-block;
    position: relative;
    top: -10px;
    left: -11px;
    padding: 5px;
}


.station-marker-line-arrival {
    color: #EE5396;
}

.station-marker-line-arrival-mag {
    color: #F4F4F4
}

.earthquake-detail-section-title {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #161616;
}

.cds--tabs div.cds--tab--list button.cds--tabs__nav-item.cds--tabs__nav-link.bea-tab-header.cds--tabs__nav-item {
    max-width: max-content !important;
    border-bottom: 0px solid #ffffff !important;
    display: flex;
    height: 48px;
    flex-direction: column;
    border-right: 1px solid var(--Tokens-Interactive-Tokens-disabled-03, #8D8D8D) !important;
    background: var(--Tokens-Core-Tokens-ui-03, #E0E0E0) !important;

    color: var(--Tokens-Core-Tokens-text-01, #161616);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;

    font-family: IBM Plex Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.16px;
}

.cds--tabs div.cds--tab--list button.cds--tabs__nav-item.cds--tabs__nav-link.bea-tab-header.cds--tabs__nav-item:last-child {
    max-width: max-content !important;
    border-bottom: 0px solid #ffffff !important;
    display: flex;
    height: 48px;
    flex-direction: column;
    border-right: 0 solid var(--Tokens-Interactive-Tokens-disabled-03, #8D8D8D) !important;
    background: var(--Tokens-Core-Tokens-ui-03, #E0E0E0) !important;

    color: var(--Tokens-Core-Tokens-text-01, #161616);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;

    font-family: IBM Plex Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.16px;
}

.cds--tabs div.cds--tab--list button.cds--tabs__nav-item.cds--tabs__nav-link.bea-tab-header.cds--tabs__nav-item--selected {
    max-width: max-content !important;
    display: flex;
    height: 48px;
    flex-direction: column;
    border-top: 2px solid #FF832B !important;
    background-color: #FFF !important;
    border-right: 0px solid var(--Tokens-Interactive-Tokens-disabled-03, #8D8D8D) !important;

    color: var(--Tokens-Core-Tokens-text-01, #161616);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;

    font-family: IBM Plex Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.16px;
}

.cds--tabs div.cds--tab--list button.cds--tabs__nav-item.cds--tabs__nav-link.bea-tab-header.cds--tabs__nav-item--selected:last-child {
    max-width: max-content !important;
    display: flex;
    height: 48px;
    flex-direction: column;
    border-top: 2px solid #FF832B !important;
    background-color: #FFF !important;
    border-right: 0px solid var(--Tokens-Interactive-Tokens-disabled-03, #8D8D8D) !important;

    color: var(--Tokens-Core-Tokens-text-01, #161616);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;

    font-family: IBM Plex Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.16px;
}

/*
.cds--tabs__nav-item {
    max-width: max-content !important;
    border-bottom: 0px solid #ffffff !important;
    display: flex;
    height: 48px;
    flex-direction: column;
    border-right: 1px solid var(--Tokens-Interactive-Tokens-disabled-03, #8D8D8D) !important;
    background: var(--Tokens-Core-Tokens-ui-03, #E0E0E0) !important;
}

.cds--tabs__nav-item--selected {
    display: flex;
    height: 48px;
    flex-direction: column;
    border-top: 2px solid #FF832B !important;
    background-color: #FFF !important;
    border-right: 0px solid var(--Tokens-Interactive-Tokens-disabled-03, #8D8D8D) !important;
}

.cds--tabs__nav-item:last-child {
    max-width: max-content !important;
    border-bottom: 0px solid #ffffff !important;
    display: flex;
    height: 48px;
    flex-direction: column;
    background: var(--Tokens-Core-Tokens-ui-03, #E0E0E0) !important;
    border-right: 1px solid var(--Tokens-Interactive-Tokens-disabled-03, #E0E0E0) !important;
}

.cds--tabs__nav-item--selected:last-child {
    max-width: max-content !important;
    border-bottom: 0px solid #ffffff !important;
    display: flex;
    height: 48px;
    flex-direction: column;
    background: var(--Tokens-Core-Tokens-ui-03, #FFFFFF) !important;
    border-right: 1px solid var(--Tokens-Interactive-Tokens-disabled-03, #FFFFFF) !important;
}


.cds--tabs__nav-item.cds--tabs__nav-link.bea-tab-header.cds--tabs__nav-item--selected {
    color: var(--Tokens-Core-Tokens-text-01, #161616);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.16px;
}

.cds--tabs__nav-item.cds--tabs__nav-link.bea-tab-header {
    color: var(--Tokens-Interactive-Tokens-disabled-03, #8D8D8D);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.16px;
}
*/
.map-tooltip {
    background: #393939;
    border-radius: 2px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.32px;
    color: #FFFFFF;
}

.map-tooltip-arrival {
    width: 8px;
    height: 8px;
    background-color: #EE5396;
    margin-right: 8px;
    border-radius: 50%;
}

.map-tooltip-magnitude {
    width: 8px;
    height: 8px;
    background-color: #4589FF;
    margin-right: 8px;
    border-radius: 50%;
}

.map-tooltip-left-arrival {
    width: 4px;
    height: 8px;
    background-color: #EE5396;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.map-tooltip-right-magnitude {
    width: 4px;
    height: 8px;
    background-color: #4589FF;
    margin-right: 8px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

/* Box toggle */
.box-toggle {
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 16px;
    gap: 10px;

    position: absolute;
    right: 24px;
    top: 16px;

    /* Tokens/Core Tokens/ui-01 */
    background: #FFFFFF;
    border-radius: 2px;
    z-index: 999;
    min-width: 196px;
}

/* Box toggle */
.box-toggle-2 {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px 16px;
    gap: 10px;

    position: absolute;
    right: 24px;
    top: 28px;

    /* Tokens/Core Tokens/ui-01 */
    background: #FFFFFF;
    border-radius: 2px;
    z-index: 999;
    min-width: 196px;
}

/* Toggle - horizontal */
.box-toggle-horizontal {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    gap: 8px;

    width: 164px;
    height: 24px;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.box-toggle-horizontal div.label {
    /* Bodies/Body short 01 */
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */
    display: flex;
    align-items: center;
    letter-spacing: 0.16px;

    /* Tokens/Core Tokens/text-01 */
    color: #161616;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.box-toggle-horizontal div.switch {}

.endpoint-notification {
    max-height: 66px;
    min-height: 48px;
}