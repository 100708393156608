.im-notification-container {
    display: grid;
    min-height: 48px;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    align-items: center;
    border-radius: 2px;
    background: #F4F4F4;
    border-left: 4px solid #FF832B;
}

.im-notification-container .ghost {
    width: 91px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-right: 16px;
    padding: 8px;
    height: 40px;
    right: 16px;
    top: calc(50% - 40px/2);
    border-radius: 2px;
}

.im-notification-container .ghost .inner {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px;
    gap: 10px;
    height: 24px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.im-notification-container .ghost .inner span {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #FF832B;
    flex: none;
    order: 0;
    flex-grow: 0;
}


.im-notification-container .ghost:hover {
    cursor: pointer;
    background-color: #e0e0e0;
}

.im-notification-container .text-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    height: 18px;
    margin-left: 16px;
}

.im-notification-container .text-container h5 {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.16px;
    color: #161616;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.im-notification-container .text-container h6 {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.16px;
    color: #525252;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.im-notification-content {
    color: #525252;
    position: relative;
    border-radius: 2px;
    margin: 16px;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
}

.open {
    display: block;
}

.close {
    display: none;
}